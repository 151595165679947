import React, { useContext, useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import moment from 'moment';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {ValidateInput, capitalizeWordFun, GetFormAuthDetails} from '../components/GlobalFunction';
import ConfirmModelSave from '../components/ConfirmModelSave';
import ConfirmModelUpdateDelete from '../components/ConfirmModelUpdateDelete';
import CRUDContext from '../components/CRUDContext';

import { emptyTempDataFun, emptyGlobalDataFun, setTempDataFun } from '../components/redux/actions';

import Cookies from 'js-cookie';

const StudentMaster = () => {   
    const [v_open_frm, setv_open_frm] = useState(false);
    const inputRefs = useRef([]);
    let response = [], updatedata = [], v_toast_msg = '';    
    const [TransType, setTransType] = useState('');
    const [ListData, setListData] = useState([]);
    const [ShowConfirmModelSave, setShowConfirmModelSave] = useState(false);
    const [ShowConfirmModelUpdateDelete, setConfirmModelUpdateDelete] = useState(false);      
    const [conditionalCSS, setConditionalCSS] = useState(false);
    const [TableRecordID, setTableRecordID] = useState(0);
    const [userAuthData, setUserAuthData] = useState([]);   

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [disableSave, setdisableSave] = useState(0);
    const [disableDelete, setdisableDelete] = useState(0);    
    const [saveUpdateTag, setsaveUpdateTag] = useState('SAVE');

    // define Redux

    const ReduxGlobalData = useSelector((state) => state.GlobalData); 
    const ReduxTempData = useSelector((state) => state.TempData);
    const ReduxDDBranchBoardData = useSelector((state) => state.DDBranchBoardData);
    const ReduxDDMonthData = useSelector((state) => state.DDMonthData);
    const ReduxDDStudentTypeData = useSelector((state) => state.DDStudentTypeData);
    const ReduxDDMediumData = useSelector((state) => state.DDMediumData);
    const ReduxDDStStreamData = useSelector((state) => state.DDStStreamData);
    const ReduxDDFeeCategData = useSelector((state) => state.DDFeeCategData);
    const DDFeePaymentTermData = useSelector((state) => state.DDFeePaymentTermData);
    const ReduxDDStudentClassData = useSelector((state) => state.DDStudentClassData);
    const ReduxDDGenderData = useSelector((state) => state.DDGenderData);
    const ReduxDDReligionData = useSelector((state) => state.DDReligionData);
    const ReduxDDCasteCategData = useSelector((state) => state.DDCasteCategData);
    const ReduxDDCasteData = useSelector((state) => state.DDCasteData);
    const ReduxDDPreviousSchoolData = useSelector((state) => state.DDPreviousSchoolData);
    const ReduxDDPreviousClassData = useSelector((state) => state.DDPreviousClassData);
    const ReduxDDStudentSectionData = useSelector((state) => state.DDStudentSectionData);
    const ReduxDDAppForData = useSelector((state) => state.DDAppForData);
    const ReduxDDOccupationData = useSelector((state) => state.DDOccupationData);
    const ReduxDDJobNatureData = useSelector((state) => state.DDJobNatureData);
    const ReduxDDRelationTypeData = useSelector((state) => state.DDRelationTypeData);    

    // define drop downs inputs
    
    const [board, setboard] = useState(0);
    const [student_type, setstudent_type] = useState(0);
    const [medium, setmedium] = useState(0);
    const [stream, setstream] = useState(0);
    const [fee_categ, setfee_categ] = useState(0);
    const [pay_term, setpay_term] = useState(0);
    const [st_class, setst_class] = useState(0);
    const [st_sec, setst_sec] = useState(0);
    const [gender, setgender] = useState(0);
	const [religion, setreligion] = useState(0);
	const [religion_category, setreligion_category] = useState(0);
	const [religion_caste, setreligion_caste] = useState(0);
	const [pre_school, setpre_school] = useState(0);
	const [pre_class, setpre_class] = useState(0);
    const [app_for, setapp_for] = useState(0);

    // define drop downs items

    const [boardItems, setboardItems] = useState([]);
    const [student_typeItems, setstudent_typeItems] = useState([]);
    const [mediumItems, setmediumItems] = useState([]);
    const [streamItems, setstreamItems] = useState([]);
    const [fee_categItems, setfee_categItems] = useState([]);
    const [pay_termItems, setpay_termItems] = useState([]);
    const [st_classItems, setst_classItems] = useState([]);
    const [st_secItems, setst_secItems] = useState([]);
	const [genderItems, setgenderItems] = useState([]);
	const [religionItems, setreligionItems] = useState([]);
	const [religion_categoryItems, setreligion_categoryItems] = useState([]);
	const [religion_casteItems, setreligion_casteItems] = useState([]);
	const [pre_schooItems, setpre_schoolItems] = useState([]);
	const [pre_classItems, setpre_classItems] = useState([]);
    const [app_forItems, setapp_forItems] = useState([]);
    const [stleft_monthItems, setstleft_monthItems] = useState([]);
    const [fat_occitems, setfat_occitems] = useState([]);   
    const [fat_jobitems, setfat_jobitems] = useState([]);  
    const [rel_with_studentitems, setrel_with_studentitems] = useState([]);

    const [full_con_categ, setfull_con_categ] = useState(0);
    const [full_con_categItems, setfull_con_categItems] = useState([]);

    // define other input
    
    const [st_name, setst_name] = useState();
    const [adm_no, setadm_no] = useState();
    const [class_roll_no, setclass_roll_no] = useState();
	const [dob, setdob] = useState();
    const [adm_date, setadm_date] = useState();
	const [fat_name, setfat_name] = useState();
	const [fat_mobile_1, setfat_mobile_1] = useState();
	const [fat_mobile_2, setfat_mobile_2] = useState();
	const [fat_aadhar_no, setfat_aadhar_no] = useState();
	const [mot_name, setmot_name] = useState();
	const [mot_mobile_1, setmot_mobile_1] = useState();
	const [mot_mobile_2, setmot_mobile_2] = useState();
	const [mot_aadhar_no, setmot_aadhar_no] = useState();
	const [local_address, setlocal_address] = useState();   
    const [what_app_mobile, setwhat_app_mobile] = useState();	
    const [fat_income, setfat_income] = useState();
    const [gur_name, setgur_name] = useState();   
    const [per_address, setper_address] = useState();
    const [fat_occ, setfat_occ] = useState();   
    const [fat_job, setfat_job] = useState();  
    const [rel_with_student, setrel_with_student] = useState(); 
    const [st_left_month, setst_left_month] = useState();     
    const [notes, setnotes] = useState();

    // define form name and navigation details

    const [v_urlSaveUpdateDelete, setv_urlSaveUpdateDelete] = useState('9_url_student_master_new_proc');

    const v_backToNavigateName = '/Dashboard';
    const v_findToNavigateName = '/StudentMasterDetails';
    const v_main_form_heading = 'Student Master';
    const v_frm_no = 9;

    const [errors_message_adm_no, setErrors_message_adm_no] = useState('');
    const [errors_adm_no, setErrors_adm_no] = useState(false);

    let v_input_result, v_active_status = 1, v_null_parameter = 'NA';
    
    // define standard errors and entry required messages

    const {        
        deletedMessage,
        createdMessage,
        updatedMessage,        
        whenStudentNotFoundMessage,                     
        WhenRecordNotFoundToDelete,
        WhenRecordNotFoundToUpdate,
        v_allow_open,
        v_allow_read,
        v_session_expire_msg,
        v_access_denied_msg,
        v_new_open,
        v_update_open,
        v_delete_open,
        v_toast_position,
        v_toast_autoClose,
        v_toast_hideProgressBar,
        v_toast_closeOnClick,
        v_toast_pauseOnHover,
        v_toast_draggable,
        v_toast_progress,
        v_toast_theme,        
    } = useContext(CRUDContext);

    useEffect(() => {
        async function loadData() {        
            const result = await GetFormAuthDetails(ReduxGlobalData[0].id, v_frm_no);
            setUserAuthData(result);          

            // console.log("result >> ", result);

            // console.log("result Open >> ", result.allow_open);
            // console.log("result New >> ", result.allow_new);
            // console.log("result Read >> ", result.allow_read);
            // console.log("result Update >> ", result.allow_update);
            // console.log("result Delete >> ", result.allow_delete);           

            if(result.res_status === 'UN_AUTHROISED' || result.res_status === 'ERROR'){

                if(result.res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
                if(result.res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

                toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });

                dispatch(emptyGlobalDataFun());

                Cookies.set('token', '');
                localStorage.clear();
                navigate("/Login");
                return;
            }

            if (result.allow_open === 2) {
                toast.info(v_allow_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                navigate(`${v_backToNavigateName}`);
                return;
            }else{

                // if load allow then execute the below code

                setv_open_frm(true);                

                setdisableSave(false);
                setdisableDelete(true);        

                setstleft_monthItems(ReduxDDMonthData[0]); 
                setst_classItems(ReduxDDStudentClassData[0]);           
                setgenderItems(ReduxDDGenderData[0]);
                setreligionItems(ReduxDDReligionData[0]);            
                setreligion_categoryItems(ReduxDDCasteCategData[0]);
                setreligion_casteItems(ReduxDDCasteData[0]);            
                setpre_schoolItems(ReduxDDPreviousSchoolData[0]);
                setpre_classItems(ReduxDDPreviousClassData[0]);
                setst_secItems(ReduxDDStudentSectionData[0]);
                setapp_forItems(ReduxDDAppForData[0]);
                setfat_occitems(ReduxDDOccupationData[0]);
                setfat_jobitems(ReduxDDJobNatureData[0]);
                setrel_with_studentitems(ReduxDDRelationTypeData[0]);
                
                if (ReduxGlobalData[0].brd === '0' && ReduxDDBranchBoardData.length > 0){
                    setboardItems(ReduxDDBranchBoardData[0]);}            

                if (ReduxGlobalData[0].st === '0' && ReduxDDStudentTypeData.length > 0){               
                    setstudent_typeItems(ReduxDDStudentTypeData[0]);}

                if (ReduxGlobalData[0].medi === '0' && ReduxDDMediumData.length > 0){                
                    setmediumItems(ReduxDDMediumData[0]);}

                if (ReduxGlobalData[0].stem === '0' && ReduxDDStStreamData.length > 0){                
                    setstreamItems(ReduxDDStStreamData[0]);}

                if (ReduxGlobalData[0].fcat === '0' && ReduxDDFeeCategData.length > 0){                
                    setfee_categItems(ReduxDDFeeCategData[0]);}

                if (ReduxGlobalData[0].ptm === '0' && DDFeePaymentTermData.length > 0){                
                    setpay_termItems(DDFeePaymentTermData[0]);
                }         
                
                const v_token = Cookies.get('token');                
                
                response = await axios.get(`url_fetch_data_branch_finyear_id_wise/38_url_param_view_branch_wise_drop_down/38/${ReduxGlobalData[0].br}/0/FULL_CON_CATEG`,
                { headers: { Authorization: `Bearer ${v_token}` } });
                updatedata = await response.data.v_out_data.v_data;
                setfull_con_categItems(await updatedata);

                setBlankFields();

                if (ReduxTempData.length > 0){
                    FindStudentDetails(ReduxTempData[0].tempuserid);
                    dispatch(emptyTempDataFun());
                }
                
            }

        }
        loadData();
    }, []);

    useEffect(() => {
        setBlankFields();
    }, [ReduxGlobalData[0]]);       

    function FindStudentDetails(p_TableRecordID) {        

        const getAllTableRecordFunction = async (var_link) => {

            const v_token = Cookies.get('token');

            response = await axios.get(`url_fetch_data/url_student_master_complete_view/${v_frm_no}/${ReduxGlobalData[0].ag}/0/0/${v_active_status}
                /${ReduxGlobalData[0].br}
                /${ReduxGlobalData[0].fy}
                /${p_TableRecordID}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}
                /${v_null_parameter}        
                `,
                { headers: { Authorization: `Bearer ${v_token}` } });
                
            // updatedata = await response.data.v_out_data.v_data;
            // response = await axios.get(`url_student_master_complete_view/${v_frm_no}/${p_TableRecordID}`,
            // { headers: { Authorization: `Bearer ${v_token}` } });
            
            updatedata = await response.data.v_out_data;   

            const returnVal = AuthenticationCheckDetailFun(updatedata, 'READ');
            if (!returnVal){return};

            updatedata = await response.data.v_out_data.v_data;

            setTableRecordID(p_TableRecordID);
            
            setadm_no(updatedata[0].adm_no);                
            setst_class(updatedata[0].st_class);
            setst_sec(updatedata[0].st_sec); 
            setclass_roll_no(updatedata[0].class_roll_no);
            setboard(updatedata[0].board);
            setstudent_type(updatedata[0].student_type);
            setmedium(updatedata[0].medium);
            setstream(updatedata[0].stream);
            setfee_categ(updatedata[0].fee_categ); 
            setpay_term(updatedata[0].pay_term);
            setst_name(updatedata[0].st_name);
            setfat_name(updatedata[0].fat_name);
            setmot_name(updatedata[0].mot_name);
            setnotes(updatedata[0].notes);
            // setshow_in_list(updatedata[0].show_in_list);
            // setactive_status(updatedata[0].active_status);
            
            setgender(updatedata[0].gender); 
            setreligion(updatedata[0].religion); 
            setreligion_category(updatedata[0].religion_category);
            setreligion_caste(updatedata[0].religion_caste);
            setpre_school(updatedata[0].pre_school);
            setpre_class(updatedata[0].pre_class);
            setfat_mobile_1(updatedata[0].fat_mobile_1); 
            setfat_mobile_2(updatedata[0].fat_mobile_2);
            setfat_aadhar_no(updatedata[0].fat_aadhar_no);
            setmot_mobile_1(updatedata[0].mot_mobile_1);
            setmot_mobile_2(updatedata[0].mot_mobile_2);
            setmot_aadhar_no(updatedata[0].mot_aadhar_no);
            setlocal_address(updatedata[0].local_address);
            setapp_for(updatedata[0].app_for);
            setwhat_app_mobile(updatedata[0].what_app_mobile);

            setfull_con_categ(updatedata[0].full_con_categ);            
            
            // console.log("Adm.Date >> ", updatedata[0].st_name, moment(updatedata[0].adm_date).format('YYYY-MM-DD'))

            setdob(moment(updatedata[0].dob).format('YYYY-MM-DD')); 
            setadm_date(moment(updatedata[0].adm_date).format('YYYY-MM-DD')); 
            
            setfat_occ(updatedata[0].fat_occ)
            setfat_job(updatedata[0].fat_job)
            setrel_with_student(updatedata[0].rel_with_student)
            setfat_income(updatedata[0].fat_income)
            setgur_name(updatedata[0].gur_name)
            setper_address(updatedata[0].per_address)

            setst_left_month(updatedata[0].st_left_month)

            if (updatedata[0].active_status === 1){
                setdisableSave(false);
                setdisableDelete(false);
            }else
            {
                setdisableSave(true);
                setdisableDelete(true);
            }

            setsaveUpdateTag('UPDATE');

        }
        getAllTableRecordFunction();        
    }

    function setBlankFields() {
        setsaveUpdateTag('SAVE');
        setdisableSave(false);
        setdisableDelete(true);        

        setTableRecordID(0);
        setboard(0);
        setst_class(0);
        setstudent_type(0);
        setmedium(0);
        setstream(0);
        setfee_categ(0);
        setpay_term(0);
        setst_class(0);
        setst_sec(0); 
        setboard(0);
        setstudent_type(0);
        setmedium(0);
        setstream(0);
        setfee_categ(0); 
        setpay_term(0);
        setapp_for(0);
        setgender(0);
        setreligion(0); 
        setreligion_category(0);
        setreligion_caste(0);
        setpre_school(0);
        setpre_class(0);
        setfat_occ(0);
        setfat_job(0);
        setrel_with_student(0);
        setst_left_month(0);
        
        setnotes('');
        setadm_no('');
        setclass_roll_no('');        
        setst_name('');
        setfat_name('');
        setmot_name('');        
        setnotes('');        
        setdob('');
        setadm_date('');        
        setfat_mobile_1(''); 
        setfat_mobile_2('');
        setfat_aadhar_no('');
        setmot_mobile_1('');
        setmot_mobile_2('');
        setmot_aadhar_no('');
        setlocal_address('');
        setwhat_app_mobile('');
        setfat_income(0);
        setfull_con_categ(0);
        
        setgur_name('');
        setper_address('');        
    }
    
    const StudentLeftFunction = (p_trans_type) => {        

        // console.log("st_left_month >> ", st_left_month)

        if (TableRecordID === 0) {
            toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                      
        }
        
        // console.log("st_left_month >> ", st_left_month)
        
        if(parseInt(st_left_month) <= 0){                        
            toast.error('Entry required for left Month !', {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;   
        }

        let insupdel_type = 'UPDATE';

        const data = {
            id: TableRecordID,
            insupdel_type,

            trans_type : p_trans_type,
            id: TableRecordID,
            under_comp_group_id : ReduxGlobalData[0].ag,
            under_comp_branch_id : ReduxGlobalData[0].br,
            fin_year : ReduxGlobalData[0].fy,
            st_left_month,
        };

        // shiv       

        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        // setv_urlSaveUpdateDelete('9_url_student_master_new_proc');

        setv_urlSaveUpdateDelete('9_student_left_proc');        

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }
    }

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleInputValueFun = (name, value) => {

        if (name === 'board') {            
            setboard(value);
            return;            
        }else if (name === 'fee_categ') {
            setfee_categ(value);
            return;            
        }else if (name === 'pay_term') {
            setpay_term(value);
            return;            
        }else if (name === 'student_type') {
            setstudent_type(value);
            return;            
        }else if (name === 'stream') {
            setstream(value);
            return;            
        }else if (name === 'medium') {
            setmedium(value);
            return;  
        }else if (name === 'adm_no') {            
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            setadm_no(v_input_result.v_value);            
            return;
        }else if (name === 'adm_date') {
            setadm_date(value);
            return;            
        }else if (name === 'st_name') {            
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setst_name(capitalizeWordFun(v_input_result.v_value));
            return;
        }else if (name === 'st_class') {
            setst_class(value);
            return;            
        }else if (name === 'st_sec') {
            setst_sec(value);
            return;            
        }else if (name === 'dob') {
            setdob(value);
            return;            
        }else if (name === 'religion_caste') {
            setreligion_caste(value);
            return;            
        }else if (name === 'religion_category') {
            setreligion_category(value);
            return;            
        }else if (name === 'religion') {
            setreligion(value);
            return;            
        }else if (name === 'gender') {
            setgender(value);
            return;            
        }else if (name === 'pre_school') {
            setpre_school(value);
            return;            
        }else if (name === 'pre_class') {
            setpre_class(value);
            return;            
        }else if (name === 'fat_name') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setfat_name(capitalizeWordFun(v_input_result.v_value));            
            return;
        }else if (name === 'fat_occ') {
            setfat_occ(value);
            return;            
        }else if (name === 'fat_job') {
            setfat_job(value);
            return;            
        }else if (name === 'fat_income') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            setfat_income(v_input_result.v_value);            
            return;
        }else if (name === 'fat_mobile_1') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            setfat_mobile_1(v_input_result.v_value);            
            return;            
        }else if (name === 'fat_mobile_2') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            setfat_mobile_2(v_input_result.v_value);            
            return;
        }else if (name === 'fat_aadhar_no') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            setfat_aadhar_no(v_input_result.v_value);            
            return;
        }else if (name === 'mot_name') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setmot_name(capitalizeWordFun(v_input_result.v_value));             
            return;
        }else if (name === 'mot_mobile_1') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            setmot_mobile_1(v_input_result.v_value);            
            return;
        }else if (name === 'mot_mobile_2') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            setmot_mobile_2(v_input_result.v_value);            
            return;
        }else if (name === 'mot_aadhar_no') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            setmot_aadhar_no(v_input_result.v_value);            
            return;
        }else if (name === 'gur_name') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setgur_name(capitalizeWordFun(v_input_result.v_value));            
            return;
        }else if (name === 'rel_with_student') {
            setrel_with_student(value);
            return;            
        }else if (name === 'what_app_mobile') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            setwhat_app_mobile(v_input_result.v_value);            
            return;
        }else if (name === 'per_address') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setper_address(capitalizeWordFun(v_input_result.v_value));            
            return;
        }else if (name === 'local_address') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setlocal_address(capitalizeWordFun(v_input_result.v_value));            
            return;
        }else if (name === 'class_roll_no') {
            v_input_result = ValidateInput('CHECK_NUMBER_ONLY', value, '', 100);           
            setclass_roll_no(v_input_result.v_value);            
            return;
        }else if (name === 'app_for') {
            setapp_for(value);
            return;            
        }else if (name === 'st_left_month') {
            setst_left_month(value);
            return;            
        }else if (name === 'notes') {
            v_input_result = ValidateInput('CHECK_ALPHA_NUMBER', value, '', 100);
            setnotes(capitalizeWordFun(v_input_result.v_value));            
            return;
        }else if(name === 'full_con_categ'){
            setfull_con_categ(value);
        }

    }

    const createRecordFunction = (event) => {
        event.preventDefault();
        const { name, value } = event.target; 
        
        let v_board = 0, v_student_type = 0, v_medium = 0, v_stream = 0, v_fee_categ = 0, v_pay_term = 0, v_class_roll_no = 0, v_gender = 0;
        let v_religion = 0, v_religion_category = 0, v_religion_caste = 0, v_pre_school = 0, v_pre_class = 0, v_dob = null, v_adm_date = null;
        let v_fat_occ = 0, v_fat_job = 0, v_rel_with_student = 0, v_fat_income = 0, v_full_con_categ = 0, insupdel_type = '';

        if (value === 'DELETE'){

            if (TableRecordID === 0){
                toast.info(WhenRecordNotFoundToDelete, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            insupdel_type = 'DELETE';
        }else{
            if (TableRecordID === 0){
                insupdel_type = 'INSERT';            
            }else{

                if (TableRecordID === 0){
                    toast.info(WhenRecordNotFoundToUpdate, {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                insupdel_type = 'UPDATE';            
            }
        }

        if (value != 'DELETE'){
            if (boardItems.length > 0){
                if(board.length === undefined || board.length === 0){
                    toast.error('Entry required for Board !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }

                v_board = board;
            }else{            
                v_board = ReduxGlobalData[0].brd;
            }

            if (student_typeItems.length > 0){
                if(student_type.length === undefined || student_type.length === 0){
                    toast.error('Entry required for Student type !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }

                v_student_type = student_type;
            }else{            
                v_student_type = ReduxGlobalData[0].st;
            }

            if (mediumItems.length > 0){
                if(medium.length === undefined || medium.length === 0){
                    toast.error('Entry required for Medium !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }

                v_medium = medium;            
            }else{
                v_medium = ReduxGlobalData[0].medi;
            }

            if(streamItems.length > 0){
                if(stream.length === undefined || stream.length === 0){
                    toast.error('Entry required for Stream !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }

                v_stream = stream;            
            }else{
                v_stream = ReduxGlobalData[0].stem;
            }        

            if (fee_categItems.length > 0){
                if(fee_categ.length === undefined || fee_categ.length === 0){
                    toast.error('Entry required for Fee category !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return;
                }

                v_fee_categ = fee_categ;            
            }else{
                v_fee_categ = ReduxGlobalData[0].fcat;
            }

            if (pay_termItems.length > 0){
                if(pay_term.length === undefined || pay_term.length === 0){
                    toast.error('Entry required for Payment term !', {position: v_toast_position,autoClose: v_toast_autoClose,
                        hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                        draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                    });
                    return; 
                }
                        
                v_pay_term = pay_term;
            }else{
                v_pay_term = ReduxGlobalData[0].ptm;
            }

            if(st_class.length === undefined || st_class.length === 0){
                toast.error('Entry required for Student class !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }

            if(st_sec.length === undefined || st_sec.length === 0){
                toast.error('Entry required for Class section !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }

            if(st_name.trim().length === 0){
                toast.error('Entry required for Student names !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            }     

            if(parseInt(app_for) <= 0){
                toast.error('Entry required for Old/New !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return; 
            } 
            
            if(parseInt(app_for) > 0){
            }else{
                // (app_for === undefined || app_for.length === undefined || app_for.length === 0){
                toast.error('Entry required for Old/New !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }        
        
            if (class_roll_no.length === 0)
            {
                v_class_roll_no = 0;
            }else{
                v_class_roll_no = class_roll_no;
            }

            if (moment(dob, "YYYY-MM-DD", true).isValid())
            {            
                v_dob = dob
            }else{            
                v_dob = null;
            }

            if (moment(adm_date, "YYYY-MM-DD", true).isValid())
            {            
                v_adm_date = adm_date
            }else{            
                v_adm_date = null;
            }        

            if(gender.length === undefined || gender.length === 0)
            {
                v_gender = 0;
            }else{
                v_gender = gender;
            }

            if(religion.length === undefined || religion.length === 0)
            {
                v_religion = 0;
            }else{
                v_religion = religion;
            }

            if(religion_category.length === undefined || religion_category.length === 0)
            {
                v_religion_category = 0;
            }else{
                v_religion_category = religion_category;
            }

            if(religion_caste.length === undefined || religion_caste.length === 0)
            {
                v_religion_caste = 0;
            }else{
                v_religion_caste = religion_caste;
            }

            if(pre_class.length === undefined || pre_class.length === 0)
            {
                v_pre_class = 0;
            }else{
                v_pre_class = pre_class;
            }

            if(pre_school.length === undefined || pre_school.length === 0)
            {
                v_pre_school = 0;
            }else{
                v_pre_school = pre_school;
            }      
            
            if(fat_occ === undefined || fat_occ.length === undefined || fat_occ.length === 0){
                v_fat_occ = 0;        
            }else{
                v_fat_occ = fat_occ;
            }

            if(fat_job === undefined || fat_job.length === undefined || fat_job.length === 0){
                v_fat_job = 0;        
            }else{
                v_fat_job = fat_job;
            }

            if(rel_with_student === undefined || rel_with_student.length === undefined || rel_with_student.length === 0){
                v_rel_with_student = 0;        
            }else{
                v_rel_with_student = rel_with_student;
            }

            if(gender === '0' || gender === undefined || gender.length === undefined || gender.length === 0){
                toast.error('Entry required for Gender !', {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;      
            }else{
                v_gender = gender;
            }

            if (fat_income === ''){
                v_fat_income = 0;        
            }else{
                v_fat_income = fat_income;        
            }

            if(full_con_categ === undefined || full_con_categ.length === undefined || full_con_categ.length === 0){
                v_full_con_categ = 0;        
            }else{
                v_full_con_categ = full_con_categ;
            }

        }        

        const data = {
            id: TableRecordID,
            insupdel_type,
            under_comp_group_id : ReduxGlobalData[0].ag,
            under_comp_branch_id : ReduxGlobalData[0].br,
            fin_year : ReduxGlobalData[0].fy,           
            board : v_board,
            st_class,
            student_type : v_student_type,
            medium : v_medium,
            stream : v_stream,
            fee_categ : v_fee_categ,
            pay_term : v_pay_term,
            st_sec,
            class_roll_no : v_class_roll_no,
            st_name,
            adm_no,	
            fat_name,	
            mot_name,		
            app_for,
            notes,
            show_in_list : 1,
            active_status : 1,

            // for student_detail table

            dob:v_dob, 
            adm_date:v_adm_date, 
            gender:v_gender, 
            religion:v_religion, 
            religion_category:v_religion_category, 
            religion_caste:v_religion_caste, 
            pre_school:v_pre_school, 
            pre_class:v_pre_class, 
            fat_mobile_1, 
            fat_mobile_2, 
            fat_aadhar_no, 
            mot_mobile_1, 
            mot_mobile_2, 
            mot_aadhar_no,
            what_app_mobile, 
            local_address,

            fat_occ:v_fat_occ,
            fat_job:v_fat_job,
            rel_with_student:v_rel_with_student,
            fat_income:v_fat_income,
            gur_name,
            per_address,

            full_con_categ:v_full_con_categ,

            // items: Array(items),
        };
        
        setListData(data);
        setConditionalCSS(true);
        setTransType(insupdel_type);

        setv_urlSaveUpdateDelete('9_url_student_master_new_proc');

        if (insupdel_type === 'INSERT'){
            setConfirmModelUpdateDelete(false);
            setShowConfirmModelSave(true);
        }else{
            setShowConfirmModelSave(false);

            if(userAuthData.allow_update === 1){
                setConfirmModelUpdateDelete(true);
            }

            if(userAuthData.allow_delete === 1){
                setConfirmModelUpdateDelete(true);
            }            
        }    
    }

    const setModelSaveUpdateDeleteFalseFunction = (p_data) => {
        
        setShowConfirmModelSave(false);
        setConfirmModelUpdateDelete(false);
        setConditionalCSS(false);        

        if (p_data.ModelYesNoAnswer === 'YES'){
            SaveRecordFunction(p_data.last_access_ip_final, p_data.last_updel_notes_final);
        }
    }

    const SaveRecordFunction = async(p_last_access_ip_final, p_last_updel_notes_final) => {

        const listFinalData = {
            ListData,
            last_access_ip_final:p_last_access_ip_final,
            'last_access_by_final': ReduxGlobalData[0].id,
            last_updel_notes_final:p_last_updel_notes_final,
        }        

        const v_token = Cookies.get('token');

        // response = await axios.post(`${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        // { headers: { Authorization: `Bearer ${v_token}` } });

        response = await axios.post(`url_save_data_new_proc/${v_urlSaveUpdateDelete}/${v_frm_no}`, listFinalData, 
        { headers: { Authorization: `Bearer ${v_token}` } });
        
        updatedata = await response.data.v_out_data;

        AuthenticationCheckDetailFun(await updatedata, 'CRUD');
    }
    
    const AuthenticationCheckDetailFun = (updatedata, p_trans_type) => {

        // if any error comes in database when insert update or delete (this will show database error handling message)

        if (updatedata.v_status.toString().trim() === '401') {
            toast.error(updatedata.err_message, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }     

        // the below code is handling for token like

        // edit in token
        // delete token
        // if user is not active
        // check if authorised user or not

        if(updatedata.v_status.toString().trim() === '402' || updatedata.v_status.toString().trim() === '405'){

            if(updatedata.v_data[0].res_status === 'UN_AUTHROISED'){v_toast_msg = v_access_denied_msg;}
            if(updatedata.v_data[0].res_status === 'ERROR'){v_toast_msg = v_session_expire_msg;}

            toast.info(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });

            dispatch(emptyGlobalDataFun());

            Cookies.set('token', '');
            localStorage.clear();
            navigate("/Login");
            return;
        }

        if (p_trans_type === 'READ'){

            // check if read allow then           

            if (updatedata.v_status.toString().trim() === '510') {
                toast.error(v_allow_read, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return false;            
            }   

            return true;
        }

        // below code to check if CRUD allow then

        // Check INSERT allow or not code is 500

        if (updatedata.v_status.toString().trim() === '500') {
            toast.error(v_new_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;                    
        }

        // Check UPDATE allow or not code is 501 

        if (updatedata.v_status.toString().trim() === '501') {
            toast.error(v_update_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        // Check DELETE allow or not code is 502

        if (updatedata.v_status.toString().trim() === '502') {
            toast.error(v_delete_open, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
            return;            
        }

        if (updatedata.v_status.toString().trim() === '200') {
            // set the new generated table row ID of this record

            setNewRecordIDFunction(updatedata.TabID, updatedata.v_data);   
            
            if (updatedata.transType === 'INSERT'){
                setdisableDelete(false);             
                setsaveUpdateTag('UPDATE');
                v_toast_msg = createdMessage;
            }else if (updatedata.transType === 'UPDATE'){
                v_toast_msg = updatedMessage;
            }else if (updatedata.transType === 'DELETE'){
                setdisableSave(true);
                setdisableDelete(true);   
                v_toast_msg = deletedMessage;
            }

            toast.success(v_toast_msg, {position: v_toast_position,autoClose: v_toast_autoClose,
                hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
            });
        }
    }

    const setNewRecordIDFunction = async(TabID, p_v_data) => {

        // console.log("TabID >> ", p_v_data[0].p_v_id, p_v_data[0].p_rec_no);        

        // console.log("TabID >> ", p_v_data[0].p_id);

        setTableRecordID(await p_v_data[0].p_id);
        // setrec_no(await p_v_data[0].p_rec_no);      
    }

    const setPopUpDDFalseFunction = (p_data) => {        
        setConditionalCSS(false);
    }    

    const handleInputFocus = (event) => {
        event.preventDefault();

        // to be used for show pop single or multi select

        // const { name, value } = event.target;        
    };

    const handleInputValue = (event) => {
        event.preventDefault();
        const { name, value } = event.target;

        // console.log('in handleInputValue function')

        handleInputValueFun(name, value);
    }
    
    const handleButtonValue = (e) => {
        e.preventDefault();
        
        const { name, value } = e.target;

        
        
        if (value === 'BTN_NEW'){            
            setBlankFields();
            return;
        }else if (value === 'BTN_PRINT'){
            
            if (TableRecordID === 0) {
                toast.error(whenStudentNotFoundMessage, {position: v_toast_position,autoClose: v_toast_autoClose,
                    hideProgressBar: v_toast_hideProgressBar,closeOnClick: v_toast_closeOnClick,pauseOnHover: v_toast_pauseOnHover,
                    draggable: v_toast_draggable,progress: v_toast_progress,theme: v_toast_theme,                
                });
                return;
            }

            return;
        }else if (value === 'BTN_EXPORT_EXCEL'){
                        
            // const checkedinputvalue = data.map(({ id, t1_id, ...item }) => item);
            // setExcelItems(checkedinputvalue);

            return;
        }else if (value === 'BTN_EXPORT_PDF'){
            
            return;
        }else if (value === 'BTN_FIND'){            
            navigate(`${v_findToNavigateName}`); 
            return;
        }else if (value === 'BTN_COPY_TO_BRANCH'){
            return;
        }else if (value === 'BTN_ASSIGN_BRANCH'){
            return;
        }else if (value === 'BTN_BACK'){                        
            navigate(`${v_backToNavigateName}`);
            return;
        }else if (value === 'BTN_STUDENT_LEFT'){                        
            StudentLeftFunction('LEFT');
            return;
        }else if (value === 'BTN_STUDENT_RECOVER'){                        
            StudentLeftFunction('RECOVER');
            return;
        }

    }

    const addInputRef = (ref, index) => {

        // console.log('in addInputRef function')

        if (ref && !inputRefs.current.includes(ref)) {
            inputRefs.current.push(ref);
            if (index === inputRefs.current.length - 1) {
                ref.onkeydown = (e) => handleKeyDown(e, index);
            }
        }
    };

    const handleKeyDown = (e, index) => {

        // console.log('in handleKeyDown function')

        if (e.key === 'Enter' || e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = index + 1;

            // console.log("Enter >> ", nextIndex, " >> ", inputRefs.current.length);            

            if (nextIndex < inputRefs.current.length) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[0].focus(); // Focus on the first input field
            }
        }

        if (e.key === 'ArrowUp') {
            e.preventDefault();

            const nextIndex = index - 1;

            // console.log("ArrowUp >> ", nextIndex);

            if (nextIndex >= 0) {
                if (nextIndex < inputRefs.current.length) {
                    inputRefs.current[nextIndex].focus();
                }
            }
        }
    };

    // const capitalizeWords = (str) => {
    //     return str
    //       .toLowerCase()
    //       .split(' ')
    //       .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    //       .join(' ');
    // };

    const goCheckDataFunction = (event) => {
        event.preventDefault();

        dispatch(emptyGlobalDataFun());

       
        
        // router.push('/')

        // console.log("ReduxTempData[0].tempuserid >> ", ReduxTempData[0].tempuserid);

        // console.log("ReduxDDBranchBoardData >> ", ReduxDDBranchBoardData);

        // navigate("/");

        
        // const v_token = Cookies.get('token');

        // console.log("From Cookies is >> ", Cookies.get('token'));        

        // console.log("userAuthData Open >> ", userAuthData.allow_open);
        // console.log("userAuthData New >> ", userAuthData.allow_new);
        // console.log("userAuthData Read >> ", userAuthData.allow_read);
        // console.log("userAuthData Update >> ", userAuthData.allow_update);
        // console.log("userAuthData Delete >> ", userAuthData.allow_delete);        

    }

return (

<>
    {
        ShowConfirmModelSave ? 
        <div className="centerWindow_css">
            <ConfirmModelSave transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

    {
        ShowConfirmModelUpdateDelete ? 
        <div className="centerWindow_css w-[32%]">
            <ConfirmModelUpdateDelete transType = {TransType} setModelSaveUpdateDeleteFalseFunction = {setModelSaveUpdateDeleteFalseFunction}/>                               
        </div>
        : null
    }

{
v_open_frm ? 

<form className={conditionalCSS ? 'form_popup_show_css_true' : 'form_popup_show_css_false'} autoComplete="off">

    <div className="top_heading_all_full_page_css">{v_main_form_heading}</div>

    <div className="form_div_main_css">
        <div className="form_div_inner_css grid grid-cols-4">

            {boardItems.length > 0 && (
                <div>
                    <label className="label_inner_page_css required_css">Board</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 0)}       
                        autoFocus         
                        name="board"
                        id="board"
                        value={board}
                        // placeholder="board"                    
                        onChange={handleInputValue}                
                        // onKeyDown={handleEnter}
                        onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}
                    > 
                        <option></option>
                        {
                            boardItems?.map((item, index) => (
                                <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                            ))
                        }
                    </select>
                </div>
            )}               

            {fee_categItems.length > 0 && (
                <div>
                    <label className="label_inner_page_css required_css">Fee Category</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 1)}                
                        name="fee_categ"
                        id="fee_categ"
                        value={fee_categ}
                        // placeholder="fee_categ"                    
                        onChange={handleInputValue}                
                        // onKeyDown={handleEnter}
                        onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}
                    >                
                    <option></option>
                    {
                        fee_categItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                    </select>
                </div>
            )}

            {pay_termItems.length > 0 && (
                <div>
                    <label className="label_inner_page_css required_css">Payment Term</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 2)}                
                        name="pay_term"
                        id="pay_term"
                        value={pay_term}
                        // placeholder="pay_term"                    
                        onChange={handleInputValue}                
                        // onKeyDown={handleEnter}
                        onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}
                    >                 
                    <option></option>
                    {
                        pay_termItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                    </select>
                </div>
            )}

            {student_typeItems.length > 0 && (
                <div>
                    <label className="label_inner_page_css required_css">Student Type</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 3)}                                    
                        name="student_type"
                        id="student_type"
                        value={student_type}
                        // placeholder="student_type"                    
                        onChange={handleInputValue}                
                        // onKeyDown={handleEnter}
                        onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}
                    >                 
                    <option></option>
                    {
                        student_typeItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                    </select>
                </div>
            )}

            {streamItems.length > 0 && (
                <div>
                    <label className="label_inner_page_css required_css">Stream</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 4)}                
                        name="stream"
                        id="stream"
                        value={stream}
                        // placeholder="stream"                    
                        onChange={handleInputValue}                
                        // onKeyDown={handleEnter}
                        onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}
                    >
                    <option></option>
                    {
                        streamItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                    </select>
                </div>
            )}

            {mediumItems.length > 0 && (                    
                <div>
                    <label className="label_inner_page_css required_css">Medium</label>
                    <select className="input_inner_page_css"
                        ref={(ref) => addInputRef(ref, 5)}                
                        name="medium"
                        id="medium"
                        value={medium}
                        // placeholder="medium"                    
                        onChange={handleInputValue}                
                        // onKeyDown={handleEnter}
                        onFocus={handleInputFocus}
                        // onBlur={handleInputBlur}
                        disabled = {saveUpdateTag === 'SAVE' ? false : true}
                    >
                    <option></option>
                    {
                        mediumItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                    </select>
                </div>
            )}
            
            <div>
                <label htmlFor="adm_no" className="label_inner_page_css required_css">Adm.No.</label>
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 6)}
                    type="text"                                
                    name="adm_no"
                    id="adm_no"
                    value={adm_no}
                    // placeholder="Adm.No."                    
                    onChange={handleInputValue}
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled
                ></input>

                <div className="error_css">{errors_adm_no ? errors_message_adm_no : null}</div>
            </div> 
            

            {/* <button className="label_inner_page_css"
                onClick={FindStudentByADMNoFunction}>
                Find
            </button> */}

            <div>
                <label htmlFor="adm_date" className="label_inner_page_css">Adm.Date</label>
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 7)}    
                    type="date"                            
                    name="adm_date"
                    id="adm_date"
                    value={adm_date}
                    // placeholder="Adm.No."                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled
                ></input>
            </div>

            <div>
                <label htmlFor="st_name" className="label_inner_page_css required_css">Name</label>        
                <input className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 8)}   
                    type="text"                             
                    name="st_name"
                    id="st_name"
                    value={st_name}
                    // placeholder="st_name"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled
                ></input>            
            </div>     

            <div>        
                <label htmlFor="st_class" className="label_inner_page_css required_css">Class</label>   
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="st_class"
                    id="st_class"
                    value={st_class}
                    // placeholder="st_class"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}                
                    disabled = {saveUpdateTag === 'SAVE' ? false : true}
                >
                    <option></option>
                    {
                        st_classItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>            
            
            <div>        
                <label htmlFor="st_sec" className="label_inner_page_css required_css">Section</label>   
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 10)}                
                    name="st_sec"
                    id="st_sec"
                    value={st_sec}
                    // placeholder="st_sec"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                
                >
                    <option></option>
                    {
                        st_secItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>     

            <div>
                <label htmlFor="dob" className="label_inner_page_css">DOB</label>   
                <input className="input_inner_page_css"
                    type="date"
                    ref={(ref) => addInputRef(ref, 11)}                
                    name="dob"
                    id="dob"
                    value={dob}
                    // placeholder="dob"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>
            </div>   
            
            <div>
                <label htmlFor="religion_caste" className="label_inner_page_css">Caste</label>   
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 12)}                
                    name="religion_caste"
                    id="religion_caste"
                    value={religion_caste}
                    // placeholder="religion_caste"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled
                >
                    <option></option>
                    {
                        religion_casteItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>    
            
            <div>
                <label htmlFor="religion_category" className="label_inner_page_css">Category</label>  
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 13)}                
                    name="religion_category"
                    id="religion_category"
                    value={religion_category}
                    // placeholder="religion_category"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                
                >
                    <option></option>
                    {
                        religion_categoryItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <label className="label_inner_page_css">Religion</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 14)}                
                    name="religion"
                    id="religion"
                    value={religion}
                    // placeholder="religion"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled
                >
                    <option></option>
                    {
                        religionItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <label className="label_inner_page_css required_css">Gender</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 15)}                
                    name="gender"
                    id="gender"
                    value={gender}
                    // placeholder="gender"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled
                >
                    <option></option>
                    {
                        genderItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <label className="label_inner_page_css">Previous School</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 12)}                
                    name="pre_school"
                    id="pre_school"
                    value={pre_school}
                    // placeholder="pre_school"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                
                >
                    <option></option>
                    {
                        pre_schooItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <label className="label_inner_page_css">Previous Class</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 13)}                
                    name="pre_class"
                    id="pre_class"
                    value={pre_class}
                    // placeholder="pre_class"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled
                >
                    <option></option>
                    {
                        pre_classItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>
                <label className="label_inner_page_css">Father's Name</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 14)}                
                    name="fat_name"
                    id="fat_name"
                    value={fat_name}
                    // placeholder="fat_name"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>            
            </div>

            <div>
                <label className="label_inner_page_css">Occupation</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 15)}                
                    name="fat_occ"
                    id="fat_occ"
                    value={fat_occ}
                    // placeholder="fat_occ"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled
                >
                    <option></option>
                    {
                        fat_occitems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div> 
            
            <div>
                <label className="label_inner_page_css">Nature of Job</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 16)}                
                    name="fat_job"
                    id="fat_job"
                    value={fat_job}
                    // placeholder="fat_job"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled
                >
                <option></option>
                {
                    fat_jobitems?.map((item, index) => (
                        <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                    ))
                }
                </select>
            </div>
            
            <div>
                <label className="label_inner_page_css">Annual Income</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 17)}                
                    name="fat_income"
                    id="fat_income"
                    value={fat_income}
                    // placeholder="fat_income"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>            
            </div>   
            
            <div>
                <label className="label_inner_page_css">Mobile-1</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 18)}                
                    name="fat_mobile_1"
                    id="fat_mobile_1"
                    value={fat_mobile_1}
                    // placeholder="fat_mobile_1"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>            
            </div>

            <div>
                <label className="label_inner_page_css">Mobile-2</label>            
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 19)}                
                    name="fat_mobile_2"
                    id="fat_mobile_2"
                    value={fat_mobile_2}
                    // placeholder="fat_mobile_2"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input> 
            </div>                
            
            <div>
                <label className="label_inner_page_css">Aadhar No.</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 20)}                
                    name="fat_aadhar_no"
                    id="fat_aadhar_no"
                    value={fat_aadhar_no}
                    // placeholder="fat_aadhar_no"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>
            </div>
            
            <div>
                <label className="label_inner_page_css">Mother's Name</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 21)}                
                    name="mot_name"
                    id="mot_name"
                    value={mot_name}
                    // placeholder="mot_name"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>            
            </div>

            <div>
                <label className="label_inner_page_css">Mobile-1</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 22)}                
                    name="mot_mobile_1"
                    id="mot_mobile_1"
                    value={mot_mobile_1}
                    // placeholder="mot_mobile_1"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>              
            </div>

            <div>
                <label className="label_inner_page_css">Mobile-2</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 23)}                
                    name="mot_mobile_2"
                    id="mot_mobile_2"
                    value={mot_mobile_2}
                    // placeholder="mot_mobile_2"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input> 
            </div>                 
            
            <div>
                <label className="label_inner_page_css">Aadhar No.</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 24)}                
                    name="mot_aadhar_no"
                    id="mot_aadhar_no"
                    value={mot_aadhar_no}
                    // placeholder="mot_aadhar_no"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>
            </div>

            <div>
                <label className="label_inner_page_css">Guardian's Name</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 25)}                
                    name="gur_name"
                    id="gur_name"
                    value={gur_name}
                    // placeholder="gur_name"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>
            </div>

            <div>
                <label className="label_inner_page_css">Relation With Student</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 26)}                
                    name="rel_with_student"
                    id="rel_with_student"
                    value={rel_with_student}
                    // placeholder="rel_with_student"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled
                >
                    <option></option>
                    {
                        rel_with_studentitems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div className="basis-1/4">
                <label className="label_inner_page_css">Whatsapp No.</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 27)}                
                    name="what_app_mobile"
                    id="what_app_mobile"
                    value={what_app_mobile}
                    // placeholder="what_app_mobile"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>            
            </div>
            
            <div className="col-span-1">
                <label className="label_inner_page_css">Permanent Address</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 28)}                
                    name="per_address"
                    id="per_address"
                    value={per_address}
                    // placeholder="per_address"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>            
            </div>

            <div className="col-span-1">
                <label className="label_inner_page_css">Correspondence Address</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 29)}                
                    name="local_address"
                    id="local_address"
                    value={local_address}
                    // placeholder="local_address"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>            
            </div>

            <div>
                <label className="label_inner_page_css">Class Roll No.</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 30)}                
                    name="class_roll_no"
                    id="class_roll_no"
                    value={class_roll_no}
                    // placeholder="class_roll_no"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>             
            </div>    

            <div>
                <label className="label_inner_page_css required_css">Old/New</label>
                <select className="input_inner_page_css"
                    ref={(ref) => addInputRef(ref, 31)}                
                    name="app_for"
                    id="app_for"
                    value={app_for}
                    // placeholder="app_for"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    disabled = {saveUpdateTag === 'SAVE' ? false : true}
                >
                    <option></option>
                    {
                        app_forItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>

            <div>        
                <label htmlFor="st_class" className="label_inner_page_css required_css">Student Left/ Recover From Month</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="st_left_month"
                    id="st_left_month"
                    value={st_left_month}                                   
                    onChange={handleInputValue}                    
                    onFocus={handleInputFocus}                    
                >
                    <option></option>
                    {
                        stleft_monthItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>  

            <div>        
                <label htmlFor="st_class" className="label_inner_page_css">Full Fee Concession (if any)</label>
                <select className="input_inner_page_css"                
                    ref={(ref) => addInputRef(ref, 9)}                
                    name="full_con_categ"
                    id="full_con_categ"
                    value={full_con_categ}                                   
                    onChange={handleInputValue}                    
                    onFocus={handleInputFocus}                    
                >
                    <option></option>
                    {
                        full_con_categItems?.map((item, index) => (
                            <option key={item.id} value={item.sel_val}>{item.sel_desc}</option>
                        ))
                    }
                </select>
            </div>          

            <div className="col-span-4">
                <label className="label_inner_page_css">Notes</label>
                <input className="input_inner_page_css"
                    type="text"
                    ref={(ref) => addInputRef(ref, 33)}
                    name="notes"
                    id="notes"
                    value={notes}
                    // placeholder="notes"                    
                    onChange={handleInputValue}                
                    // onKeyDown={handleEnter}
                    onFocus={handleInputFocus}
                    // onBlur={handleInputBlur}
                    // disabled                          
                ></input>              
            </div>
            
        </div>
    </div>  

    <nav className="btn_nav_inner_css">
    <div className="btn_div_main_css">

        <div className="btn_div_inner_css">

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_new_css"
                            value = 'BTN_NEW'
                            onClick={handleButtonValue}>
                            New
                        </button>
                    )}
                </div>

                <div>
                    {userAuthData.allow_new === 1 && (
                        <button className="btn_save_css"
                            disabled={disableSave}
                            onClick={createRecordFunction}>
                            Save
                        </button>
                    )}
                </div>

                <div>                
                    {userAuthData.allow_delete === 1 && (
                        <button className="btn_delete_css"
                            value = 'DELETE'
                            disabled={disableDelete}
                            onClick={createRecordFunction}>
                            Delete
                        </button>
                    )}
                </div>               
                    
                {/* <div>
                    {userAuthData.print === 1 && (
                        <button className="btn_print_css"
                            value = 'BTN_PRINT'
                            onClick={handleButtonValue}>
                            Print
                        </button>
                    )}
                </div> */}

                {/* <div>
                    {userAuthData.export_excel === 1 && (
                        <button className="btn_export_excel_css"
                            value = 'BTN_EXPORT_EXCEL'
                            onClick={handleButtonValue}>
                            Export Excel
                        </button>
                    )}
                </div> */}
                
                {/* <div>
                    {userAuthData.export_pdf === 1 && (
                        <button className="btn_export_pdf_css"
                            value = 'BTN_EXPORT_PDF'
                            onClick={handleButtonValue}>
                            Export PDF
                        </button>
                    )}
                </div> */}

                <div>
                    {userAuthData.allow_read === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_FIND'
                            onClick={handleButtonValue}>
                            Find
                        </button>
                    )}
                </div>

                {/* <div>
                    {userAuthData.copy_to_branch === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_COPY_TO_BRANCH'
                            onClick={handleButtonValue}>
                            Copy To Branch
                        </button>
                    )}
                </div> */}

                {/* <div>
                    {userAuthData.assign_branch === 1 && (
                        <button className="btn_find_css"
                            value = 'BTN_ASSIGN_BRANCH'
                            onClick={handleButtonValue}>
                            Assigned Branch
                        </button>
                    )}
                </div> */}                

                <div>
                    <button className="btn_back_css"
                        value = 'BTN_BACK'
                        onClick={handleButtonValue}>
                        Back
                    </button>
                </div>

                <div>
                    <button className="btn_back_css"
                        value = 'BTN_STUDENT_LEFT'
                        onClick={handleButtonValue}>
                        Left Student
                    </button>
                </div>

                <div>
                    <button className="btn_back_css"
                        value = 'BTN_STUDENT_RECOVER'
                        onClick={handleButtonValue}>
                        Recover Student
                    </button>
                </div>

                {/* <div>
                    <button className="btn_back_css"
                        onClick={goCheckDataFunction}>
                        Check Data
                    </button>
                </div> */}
                
        </div>
        
    </div>
    </nav>

    </form>
:null
}

</>
);};

export default StudentMaster;

{/* <div class="mx-auto max-w-xs">
    <div>
        <div class="relative">
        <input type="text" id="example13" placeholder=" " class="peer block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-400 focus:ring focus:ring-primary-200 focus:ring-opacity-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500" />
        <label for="example13" class="peer-focus:base absolute left-2 top-0 z-10 -translate-y-2 transform bg-white px-1 text-xs text-gray-500 transition-all peer-placeholder-shown:translate-y-3 peer-placeholder-shown:text-sm peer-focus:-translate-y-2 peer-focus:text-xs peer-disabled:bg-transparent">Full name</label>
        </div>
    </div>
</div> */}
